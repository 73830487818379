/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from '@emotion/styled';
import IllnessJPG from '@assets/images/illness.jpg';
import IllnessMobileJPG from '@assets/images/illness-mobile.jpg';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';
import { useTranslationContext } from '~/pages/sportmedizin';
//import { useTranslationContext as useTranslationContextEn } from '~/pages/en/traumatic-brain-injury';
import { ContainerSmall } from '~/utils/styles/utils';

const SportsMedPage: React.FC = () => {
  const t = useTranslationContext();

  return (
    <>
      <HeaderFooterLayout
        desktopHero={IllnessJPG}
        mobileHero={IllnessMobileJPG}
        altHero="Modell eines Gehirns zur Krankheitserklärung."
      >
        <ContainerSmall>
          <List>
            <div
              dangerouslySetInnerHTML={{
                __html: t('SportsMedContent1'), // || tEn('SportsMedContent1')
              }}
            />
          </List>
        </ContainerSmall>
      </HeaderFooterLayout>
    </>
  );
};

const List = styled.div`
  font-size: var(--primary-font-size);
  line-height: 1.5;

  ul {
    list-style: none;
    text-indent: -0.8rem;
    padding-left: 3rem;
  }

  li::before {
    content: '- ';
    padding-right: 0.3rem;
  }
`;

export default SportsMedPage;
